<template>
  <div class="row m-5 justify-content-center h-100 align-items-center">
    <div class="col-lg-8 ">
      <div
        class="position-absolute bg-white d-flex w-100 h-100 zindex-1 justify-content-center align-items-center"
        ref="loader"
      >
        <div class="spinner spinner-primary"></div>
      </div>
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label font-weight-boldest">
              Ταμείο
            </h2>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-5">
              <div class="card-body">
                <!--begin::Heading-->
                <!--begin::Form Group-->
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">* Όνομα κατόχου</label>
                  <div class="col-lg-9 col-xl-9">
                    <input
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      ref="cardOwnerName"
                    />
                  </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">* Αριθμός κάρτας</label>
                  <div class="col-lg-9 col-xl-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        ref="cardNumber"
                      />
                    </div>
                  </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                  <div class="col-lg-4 col-xl-4">
                    <label class="col-xl-12 col-lg-12 col-form-label">* Μήνας λήξης</label>
                    <div class="input-group input-group-lg input-group-solid">
                        <select
                            name="expirationMonth"
                            class="form-control form-control-solid form-control-lg"
                            ref="expirationMonth"
                        >
                            <option value="">Επιλέξτε μήνα</option>
                            <option v-for="month in monthsAndYears" :key="month" :value="month">{{month}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4">
                    <label class="col-xl-12 col-lg-12 col-form-label">* Έτος λήξης</label>
                    <div class="input-group input-group-lg input-group-solid">
                      <select
                            name="expirationYear"
                            class="form-control form-control-solid form-control-lg"
                            ref="expirationYear"
                        >
                            <option value="">Επιλέξτε έτος</option>
                            <option v-for="year in futureYears" :key="year" :value="year">{{year}}</option>
                        </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-xl-4">
                    <label class="col-xl-12 col-lg-12 col-form-label">* CVC</label>
                    <div class="input-group input-group-lg input-group-solid">
                      <input
                        class="form-control form-control-lg form-control-solid"
                        type="text"
                        ref="cvc"
                      />
                    </div>
                  </div>
                </div>
              </div>
            <!-- <div id="card-element">
              Stripe.js injects the Card Element
            </div> -->
          </div>
          <div class="d-flex justify-content-end">
            <button
              ref="pay-button"
              class="btn btn-success font-weight-boldest"
            >
              Πληρωμή
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import { CREATE_PAYMENT_INTENT, GET_PAYMENT_METHOD } from "@/core/services/store/stripe.module";
import Swal from "sweetalert2";

export default {
  name: "checkout",
  data() {
    return {
      monthsAndYears: this.range(1, 12, 1),
      futureYears: this.range(2021, 2100, 1),
    }
  },
  mounted() {
    if (
      !("caseId" in this.$route.params) ||
      this.$route.params.caseId == null
    ) {
      this.$router.push({ name: "cases" });
    } else {
      this.$store.dispatch(GET_PAYMENT_METHOD)
      .then(() => {
        if(Object.keys(this.currentPaymentMethod).length > 0){
          this.$refs.expirationMonth.value = this.currentPaymentMethod.expirationMonth || null;
          this.$refs.expirationYear.value = this.currentPaymentMethod.expirationYear || null;
          this.$refs.cvc.value = this.currentPaymentMethod.cardLastDigits ? '***' : null;
          this.$refs.cardNumber.value = this.currentPaymentMethod.cardLastDigits ? '**** **** **** ' + this.currentPaymentMethod.cardLastDigits : null;
        } else {
          Swal.fire("Δε βρέθηκε αποιηκευμένη κάρτα. Παρακαλώ προσθέστε μία για να ολοκλήρώσετε την αγορά");
          this.$router.push({ name: "profile" });
        }
      });

      (async () => {
        this.stripe = await loadStripe(
          this.$store.getters.apiKey
        ).catch(() => {});

        this.clientSecret = await this.$store
          .dispatch(CREATE_PAYMENT_INTENT, {
            assignment_id: this.$route.params.caseId
          })
          .catch(() => {});

        this.$refs["loader"].classList.remove("d-flex");
        this.$refs["loader"].classList.add("d-none");

        this.$refs["pay-button"].addEventListener("click", () => {
          this.payWithCard();
        });
      })();
    }
  },
  computed: {
    ...mapGetters(["currentPaymentMethod"])
  },
  methods: {
    range(start, stop, step) {
      var a = [start], b = start;
      while (b < stop) {
          a.push(b += step || 1);
      }
      return a;
    },
    loading(isLoading) {
      if (isLoading) {
        this.$refs["pay-button"].disabled = true;
        this.$refs["pay-button"].classList.add(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      } else {
        this.$refs["pay-button"].classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.$refs["pay-button"].disabled = false;
      }
    },
    payWithCard() {
      let vm = this;
      vm.loading(true);
      this.stripe
        .confirmCardPayment(this.clientSecret)
        .then(function(result) {
          if (result.error) {
            Swal.fire({
              title: "",
              text: result.error.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          } else {
            Swal.fire({
              title: "",
              text: "Case purchase successful",
              icon: "success",
              footer:
                '<a href="https://dashboard.stripe.com/test/payments/' +
                result.paymentIntent.id +
                '">Stripe dashboard</a>',
              heightAuto: false
            }).then(() => {
              this.$router.push({ name: "customers" });
            });
          }
        })
        .catch(e => {
          Swal.fire({
            text: JSON.stringify(e),
            icon: "error",
            heightAuto: false
          });
        })
        .finally(() => {
          vm.loading(false);
        });
    }
  }
};
</script>
